@import "../../assets/theme/vars";

.content_text_board {
  background: var(--primary_color);
  position: relative;
  padding: 0.5rem;
  border-radius: 8px;
  color: #ffffff;
  max-height: 70vh;
  overflow: auto;
  @include scrollbar_vertical;
  & > div {
    position: relative;
    z-index: 9;
    .item_cat_board {
      padding: 0.75rem;
      border-bottom: 1px solid #cccccc;
      cursor: pointer;
      h5 {
        font-size: 1rem;
      }
      &:last-child {
        border: 0;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

#recognition_form {
  .form-control {
    background: white;
    &.textarea {
      height: 85px;
    }
  }
}

.box_ranking {
  .item_ranking {
    border-left: 4px solid orange;
    padding-left: 1rem;
    .content_item_ranking {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .box_avatar_user {
      .avatar {
        width: 60px;
        height: 60px;
        margin: 0 auto;
      }
    }
  }
}

.my_modal {
  background: rgba(0, 0, 0, 0.65);
  .modal-header {
    padding: 0.75rem 1rem;
  }
  .modal-body {
    padding: 1rem;
    max-height: 80vh;
    overflow: auto;
    .item_acknowledgment {
      border-left: 5px solid orange;
      padding: 0.3rem 0;
      padding-left: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 0.75rem;
      .box_avatar_user {
        .avatar {
          width: 60px;
          min-width: 60px;
          height: 60px;
          margin: 0 auto;
        }
      }
    }
  }
}

.box_content_board {
  position: relative;
  .back_ico {
    display: none;
    & > span {
      background: $white_text;
      padding: 0.35rem;
      border-radius: 5px;
      color: #666666;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
    }
  }
  .box_ranking_new{
    text-align: right;
    text-decoration: underline;
    font-size: .90rem;
    font-family: 'is_medium';
    margin-bottom: .80rem;
    color: var(--primary_color);
  }
  .box_recognition{
    display: none;
    margin-top: 1.5rem;
    fieldset{
      border-radius: 6px;
    }
    &.is-show{
      display: block;
    }
  }
}
