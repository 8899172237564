@import "../../assets/theme/vars";

.wrapper_header {
  background: #ffffff;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
  width: 100%;
  padding: 0 1rem;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  .box_brand {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 195px;
    min-width: 195px;
    .btn_menu {
      display: none;
      margin-right: 0.75rem;
      max-width: 26px;
      width: 100%;
      height: 20px;
      position: relative;
      top: 0;
      z-index: 2;
      cursor: pointer;
      &:hover {
        color: royalblue;
      }
      & span {
        width: 100%;
        height: 4px;
        border-radius: 4px;
        display: block;
        position: absolute;
        background: var(--primary_color);
        transition: all 0.35s ease;
        transform-origin: 0px 100%;
        &:nth-child(2) {
          top: calc(50% - 2px);
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
      &.visible_menu {
        right: -5px;
        span:nth-child(1) {
          transform: rotate(45deg) translate(-3px, 2px);
        }
        span:nth-child(2) {
          opacity: 0;
        }
        span:nth-child(3) {
          transform: rotate(-45deg) translate(-2px, 1px);
        }
      }
    }
    img {
      max-height: 50px;
    }
  }
  .content_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box_search_input {
      .input-group {
        .input-group-prepend {
          .input-group-text {
            background: $bg_grey;
            border-radius: 30px 0 0 30px;
          }
        }
        .form-control {
          border-radius: 0 30px 30px 0;
          height: calc(1.5em + 0.5rem + 2px);
          background: $bg_grey;
        }
      }
    }
    .box_options {
      margin-left: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn_notifications {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 1rem;
        height: 100%;
        cursor: pointer;
        .icon_bell {
          position: relative;
          .far {
            font-size: 1.5rem;
          }
          .bullet {
            background: var(--primary_color);
            position: absolute;
            color: #ffffff;
            top: -8px;
            right: -10px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            font-size: 0.7rem;
            font-family: "is_medium";
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &:hover .icon_bell {
          color: var(--primary_color);
        }
      }
      .dropdown-menu {
        min-width: 300px;
        padding: 0;
        border: 0;
        border-radius: .5rem;
        box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
        .content_box_notif {
          .notif_ttl {
            padding: .75rem;
            color: #efefef;
            border-radius: .5rem .5rem 0 0;

            line-height: 1;
            background: var(--primary_color);
            margin-bottom: .25rem;
          }
        }
        .item_notification {
          padding: 0.5rem .75rem;
          display: block;
          border-bottom: 1px solid $bg_grey;
          cursor: pointer;
          &:hover {
            background: #eeeeee;
          }
          &:last-child {
            border: 0;
          }
        }
        .notif_empty{
          text-align: center;
          display: flex;
          flex-direction: column;
          padding: 1rem .5rem;
          color: #dedede;
          .far{
            font-size: 3rem;
            margin-bottom: .25rem;
          }
          span{
            color: #cccccc;
          }
        }
      }
      .item_options {
        font-size: 1.25rem;
        cursor: pointer;
        &:hover {
          color: var(--primary_color);
        }
        .hydrated{
          font-size: 1.8rem;
        }
      }
    }
  }
}
