@import "../../../../assets/theme/vars";

.wrapper_purchased_item {
	.body_purchased_item {
		border-top: 1px solid #cccccc;
		.info_item_shopping {
			.content_item_shopping {
				padding: 0.75rem;
				border-right: 1px solid #cccccc;
				border-top: 1px solid #cccccc;

				&:first-child {
					border-top: 0;
				}

				.bg_logo_shop {
					background: #ffffff;
				}

				.item_purchased {
					font-weight: 300;
					font-size: 0.85rem;
					margin-bottom: 1.5rem;

					& > span:first-child,
					& > span:last-child {
						padding-right: 0.5rem;
						font-weight: 600;
					}

					& > span:last-child {
						padding-left: 0.75rem;
					}

					&:last-child {
						margin: 0;
					}
				}
			}
		}

		.action_item_shopping {
			text-align: center;
			padding: 1rem 0 !important;
			& > p {
				margin: 0;
				line-height: 1;
				&:first-child {
					margin-bottom: 0.4rem;
				}
			}
		}
	}
}
