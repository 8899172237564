@import "../../assets/theme/vars";

.wrapper_chats {
    position: fixed;
    z-index: 99;
    bottom: 30px;
    right: 15px;
    color: #333;
    width: 100%;
    max-width: 400px;
    max-height: 20px;
    height: 100%;

    &.open {
        max-height: 650px;
        bottom: 0;

        .sec_ttl_chat {
            .ttl_chats {
                width: 100%;
                border-radius: 0.5rem 0.5rem 0 0;
                // margin-bottom: 0;
            }
        }

        .sec_content_chats {
            opacity: 1;
        }
    }

    .sec_ttl_chat {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .ttl_chats {
            background: var(--primary_color);
            color: #ffffff;
            width: 160px;
            height: 38px;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            line-height: 1;
            font-size: 1.1rem;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
            padding-left: 0.75rem;
            position: relative;
            border-radius: 0.5rem;
            z-index: 9;

            .btn_menu_chat {
                display: none;
                margin-right: 0.3rem;
                max-width: 26px;
                width: 100%;
                height: 20px;
                position: relative;
                top: 2px;
                z-index: 2;
                cursor: pointer;

                & span {
                    width: 20px;
                    height: 2px;
                    border-radius: 4px;
                    display: block;
                    position: absolute;
                    background: #ffffff;
                    transition: all 0.35s ease;
                    transform-origin: 0px 100%;

                    &:nth-child(2) {
                        top: calc(50% - 4px);
                    }

                    &:nth-child(3) {
                        bottom: 6px;
                    }
                }

                &.visible_menu {
                    right: -5px;

                    span:nth-child(1) {
                        transform: rotate(45deg) translate(-4px, 2px);
                    }

                    span:nth-child(2) {
                        opacity: 0;
                    }

                    span:nth-child(3) {
                        transform: rotate(-45deg) translate(-2px, -1px);
                    }
                }
            }

            .dots_notifications {
                background: #ffffff;
                color: var(--primary_color);
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 0.15rem;
                line-height: 0;
                border-radius: 50%;
                font-size: 0.8rem;
                margin-left: 0.45rem;
            }

            .btn_expand {
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0px;
                width: 35px;
                height: 38px;
                border-radius: 0 0.5rem 0.5rem 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.25rem;

                &:hover {
                    background: rgba(0, 0, 0, 0.3);
                }
            }
        }
    }

    .sec_content_chats {
        // background: #f5f7fb9f;
        background: #f5f7fb;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
        height: calc(100% - 38px);
        position: relative;
        opacity: 0;

        .content_navbar_chat {
            background: #ffffff;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 99;
            position: absolute;
            top: 0;
            left: 0;
            width: 60px;
            min-width: 60px;
            height: 100%;

            .navbar_chat {
                height: 100%;
                padding: 1rem 0;

                .item_navbar {
                    color: #999999;
                    cursor: pointer;
                    text-align: center;
                    margin-bottom: 0.15rem;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .name_secc {
                        font-size: 0.55rem;
                        font-weight: 600;
                        margin-top: 0.25rem;
                    }

                    &.sec_active {
                        color: var(--primary_color);
                        background: #ebeef5;
                        // border-left: 4px solid var(--primary_color);
                    }

                    &:hover {
                        background: #ebeef5;
                    }
                }
            }

            .myuser_chats {
                padding: 0 0.4rem;
                height: 60px;
                cursor: pointer;
            }
        }

        .content_sec_chats {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: calc(100% - 60px);
            @include scrollbar_vertical;
            overflow: auto;
        }
    }

    .box_avatar_user.in_chat {
        position: relative;

        .status_color {
            background: #ffffff;
            width: 13px;
            height: 13px;
            position: absolute;
            z-index: 1;
            box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
            border-radius: 50%;
            border: 2px solid #ffffff;
            bottom: -1px;
            right: 0;

            &.active {
                background: #59ce4f;
            }

            &.occupied {
                background: #fcbb2a;
            }

            &.disconnected {
                background: #db2020;
            }
        }
    }

    .wrapper_searches {
        position: sticky;
        top: 0;
        padding: 0.5rem 0 0.75rem;
        background: #f4f7fb;
        z-index: 2;

        .form-control {
            border: 0;
            background: #e6ebf5;
            height: calc(1.5em + 0.9rem + 2px);
            padding-left: 2.25rem;
        }

        .ico_form {
            position: absolute;
            top: 20px;
            left: 12px;
            z-index: 9;
            color: #666;
        }
    }

    .wrapper_list_chats {
        width: 100%;
        height: 100%;

        .ttlsChats:first-child {
            margin-bottom: 0;
        }

        &.gr {
            position: relative;

            .box_groups {
                line-height: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0.5rem;

                .ttlsChats {
                    margin: 0;
                }

                small {
                    line-height: 0;
                }

                .btn-new-group {
                    background: none;
                    font-size: 0.85rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    outline: none;
                    padding: 0.35rem 0.5rem;
                    border-radius: 0.15rem;
                    border: 0;
                    cursor: pointer;

                    span {
                        line-height: 0;
                        padding-left: 0.2rem;
                        margin-top: 0.13rem;
                    }

                    &:hover {
                        background: #e6ebf5;
                    }
                }
            }
        }

        .sec_new_group {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 9;

            &.open_new_group {
                display: block;
            }

            .cont_new_group__info {
                background: #f4f7fb;
                height: 100%;
                overflow: auto;
                padding: 1rem;

                .drawer_avatar_gp {
                    position: relative;

                    .toback {
                        position: absolute;
                        top: -5px;
                        left: -5px;
                        width: 25px;
                        height: 30px;
                        border-radius: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        z-index: 1;

                        &:hover {
                            background: #e0e0e0;
                        }
                    }

                    .box_avatar_user {
                        .avatar {
                            width: 150px;
                            min-width: 150px;
                            height: 150px;
                            margin: 0 auto;
                            border: 5px solid #dcdce4;
                        }
                    }

                    input[type="file"] {
                        display: none;
                    }

                    .icons {
                        position: absolute;
                        width: 33px;
                        height: 33px;
                        border-radius: 50%;
                        box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.35);
                        cursor: pointer;
                        background: #ffffff;
                    }

                    .ico_save {
                        width: 35px;
                        height: 35px;
                        color: #ffffff;
                        bottom: -14px;
                        left: calc(50% - 25px);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #339e1d;
                        border: 0;
                        outline: none;
                    }

                    .custom-file-upload {
                        display: inline-block;
                        padding: 6px;
                        bottom: -15px;
                        left: calc(50% + 15px);
                        font-size: 1.25rem;

                        &:hover {
                            background: var(--primary_color);
                            color: #ffffff;
                        }
                    }
                }

                .drawer_info_gp {
                    margin-top: 2rem;
                }
            }

            .cont_new_group__contact {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 100%;
                background: #f4f7fb;

                .box_inputs {
                    position: relative;
                    padding: 1rem 1.5rem 1rem 0.5rem;
                    background: #fff;
                    margin-bottom: 0.5rem;
                    border-bottom: 2px solid #efefef;
                    display: flex;
                    align-items: center;

                    .to_back_gr {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        min-width: 25px;
                        margin-right: 0.4rem;

                        &:hover {
                            background: #f5f7fb;
                        }
                    }

                    .box_search_contact {
                        width: 100%;

                        .form-control {
                            border: 0;
                            border-radius: 0;
                            border-bottom: 2px solid #dedede;
                            height: calc(1.25em + 0.5rem + 1px);
                            padding-left: 0;

                            &::placeholder {
                                color: #bababa;
                            }

                            &:focus {
                                outline: none;
                                border-bottom: 2px solid var(--primary_color);
                                box-shadow: none;
                            }
                        }
                    }

                    .ico_form {
                        position: absolute;
                        top: 17px;
                        right: 25px;
                        z-index: 9;
                    }
                }

                .box_list_contact {
                    width: 100%;
                    padding: 0 1rem 0.5rem;
                    height: 100%;
                    overflow: auto;

                    .inputGroup {
                        display: block;
                        margin: 0 0 5px;
                        position: relative;

                        label {
                            padding: 7px;
                            width: 100%;
                            border-radius: 0.25rem;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            text-align: left;
                            color: #3c454c;
                            cursor: pointer;
                            position: relative;
                            z-index: 2;
                            transition: color 200ms ease-in;
                            overflow: hidden;

                            &:before {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                content: "";
                                background-color: var(--primary_color);
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%) scale3d(1, 1, 1);
                                transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
                                opacity: 0;
                                z-index: -1;
                            }

                            &:after {
                                width: 20px;
                                height: 20px;
                                content: "";
                                border: 2px solid #d1d7dc;
                                background-color: #fff;
                                background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
                                background-repeat: no-repeat;
                                background-position: -2px -1px;
                                border-radius: 5px;
                                z-index: 2;
                                position: absolute;
                                right: 15px;
                                top: 50%;
                                transform: translateY(-50%);
                                cursor: pointer;
                                transition: all 200ms ease-in;
                            }

                            .box_avatar_user {
                                width: 38px;
                                min-width: 38px;
                                height: 38px;

                                .avatar {
                                    border: 2px solid #fefefe;
                                }
                            }

                            .name_chat {
                                max-width: 190px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                padding-left: 0.3rem;
                            }
                        }

                        input:checked ~ label {
                            color: #fff;

                            &:before {
                                transform: translate(-50%, -50%) scale3d(56, 56, 1);
                                opacity: 1;
                            }

                            &:after {
                                background-color: #54e072;
                                border-color: #54e072;
                            }
                        }

                        input {
                            width: 32px;
                            height: 32px;
                            order: 1;
                            z-index: 2;
                            position: absolute;
                            right: 30px;
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
                            visibility: hidden;
                        }
                    }
                }

                .box_btns_options {
                    background-color: #ffffff;
                    border-top: 2px solid #efefef;
                    text-align: center;
                    padding: 0.75rem 0 1rem;
                }
            }
        }
    }
    // ==  ITEMS CHATS ==
    .box_item_chat {
        padding: 0.55rem 0.4rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        cursor: pointer;

        &:hover {
            background: #e6ebf5;

            .info_item_chat {
                .options {
                    display: flex;
                }
            }
        }

        &.new-mnj {
            background: var(--primary_color);
            color: #ffffff;

            &:hover {
                background: var(--primary_color);
            }
        }

        .box_avatar_user {
            width: 40px;
            min-width: 40px;
            height: 40px;

            .avatar {
                border: 2px solid #fefefe;
            }
        }

        .info_item_chat {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.25rem 0 0.45rem;

            .name_chat {
                max-width: 175px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 0.95rem;
            }

            .time {
                font-size: 0.8rem;
            }

            .options {
                display: none;
                justify-content: space-between;

                .opt_chat {
                    margin-left: 0.25rem;
                    cursor: pointer;
                    width: 27px;
                    height: 27px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 0.35rem;
                    font-size: 0.9rem;
                    color: #777777;
                    background-color: #cdd1da;

                    &:hover {
                        color: #ffffff;
                        background: var(--primary_color);
                    }
                }
            }
        }
    }

    .wrapper_contacts {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .content_contacts {
            margin-bottom: 1rem;

            .letter_divider {
                position: relative;

                span {
                    font-weight: 900;
                    color: var(--primary_color);
                    background: #f4f7fb;
                    padding: 0.25rem 0.5rem;
                    margin-left: 0.5rem;
                    z-index: 1;
                    position: relative;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 45%;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    background: #dee3ee;
                }
            }
        }
    }

    .wrapper_chat_conversations {
        background: url(../../assets/imgs/bgconversations.jpg) no-repeat top center;
        background-size: cover;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .sec_info_chats {
            background: #ffffff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.75rem 0.5rem 0.75rem 0.25rem;
            box-shadow: 5px 0 12px rgba(0, 0, 0, 0.15);
            position: relative;
            z-index: 9;

            .to_back {
                color: #666666;
                cursor: pointer;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                margin-right: 0.15rem;
            }

            .box_avatar_user {
                width: 40px;
                min-width: 40px;
                height: 40px;
            }

            .box_info {
                width: 100%;
                margin-left: 0.5rem;

                .ttlsChats {
                    margin: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 215px;
                }

                p {
                    font-size: 0.9rem;
                    color: #666;
                }
            }

            .btn-group {
                margin: 0;
            }

            .dropdown-toggle::after {
                display: none;
            }

            .dropdown-menu {
                border-radius: 7px;
                box-shadow: -2px 2px 12px rgba(0, 0, 0, 0.15);
                border: 0;

                .dropdown-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0.25rem;
                    padding-left: 0.5rem;

                    span:first-child {
                        max-width: 75px;
                        white-space: normal;
                        line-height: 1.15;
                        font-size: 0.85rem;
                        font-weight: 600;
                    }
                }
            }

            .btn_ico_more {
                height: 25px;
                min-width: 25px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                    background: #e0e3eb;
                }
            }
        }

        .sec_conten_conversations {
            height: 100%;
            overflow: auto;
            display: flex;
            flex-direction: column-reverse;
            @include scrollbar_vertical;

            .box_item_conversation {
                margin-bottom: 1rem;

                &.out {
                    display: flex;
                    justify-content: flex-end;

                    .item_conversation {
                        .cont_conversation {
                            background: var(--primary_color);
                            border-radius: 13px 13px 0 13px;
                            color: #ffffff;
                        }

                        .datatime_mnj {
                            text-align: right;
                        }
                    }
                }

                &.in {
                    .item_conversation {
                        margin-left: .25rem;

                        .cont_conversation {
                            box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
                            background: #ffffff;
                            border-radius: 13px 13px 13px 0;
                        }
                    }
                }

                .item_conversation {
                    max-width: 80%;
                    width: auto;

                    .cont_conversation {
                        word-break: break-all;
                        padding: 0.65rem 0.85rem .5rem;

                        .info_mnj {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 0.85rem;
                            margin-bottom: 0.25rem;
                            font-weight: 600;
                            line-height: 1;

                            .author_mnj {
                                padding-top: 0.1rem;
                            }

                            .box_check_mnj {
                                font-size: 0.8rem;
                                margin-left: 0.5rem;

                                .check_mnj {
                                    margin-left: 0.3rem;
                                    font-size: 0.75rem;
                                }
                            }
                        }

                        .mnj {
                            margin-bottom: 0;
                            line-height: 1.15;
                            font-size: 0.98rem;
                        }
                    }

                    .datatime_mnj {
                        font-size: .75rem;
                        margin-top: .15rem;
                        color: #aaa;

                        time {
                            text-transform: initial;
                        }
                    }
                }
            }
        }

        .sec_content_typing {
            background: #ffffff;
            padding: 1rem 0.5rem 1rem 0.75rem;
            border-top: 1px solid #efefef;

            .box_intro_text {
                margin-bottom: 0;
                display: flex;

                .form-control {
                    border-radius: 40px;
                    resize: none;
                    background: #f0f0f3;

                    &:focus {
                        box-shadow: none;
                    }
                }

                .btn {
                    box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.15);
                    min-width: 48px;
                    height: 48px;
                    margin-left: 0.25rem;
                    background: var(--primary_color);
                    color: #ffffff;
                    border-radius: 50%;
                    margin-bottom: 0 !important;

                    &::before {
                        border-radius: 0 6px 6px 0;
                    }
                }
            }
        }
    }

    .wrapper_profile_chat {
        .sec_avatar_profile {
            min-height: 250px;
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            border-radius: 5px;

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 75%;
                background: rgb(0, 0, 0);
                background: linear-gradient( 0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 60% );
            }

            .to_back {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22px;
                height: 22px;
                background-color: #ffffff;
                box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.15);
                border-radius: 50%;
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
                font-size: 0.9rem;
            }

            .to_edit_avatar {
                cursor: pointer;
                color: #ffffff;
                border-radius: 50%;
                position: absolute;
                top: 0.5rem;
                right: 0.5rem;
            }

            .content_info_profile {
                color: #ffffff;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 0.75rem;

                .drawer_drop_status {
                    cursor: pointer;

                    .dots_status {
                        border-radius: 50%;
                        display: inline-block;
                        width: 11px;
                        height: 11px;
                        border: 2px solid #ffffff;
                        margin-right: 0.25rem;

                        &.active {
                            background: #59ce4f;
                        }

                        &.occupied {
                            background: #fcbb2a;
                        }

                        &.disconnected {
                            background: #db2020;
                        }
                    }
                }
            }
        }

        .sec_info_profile {
            margin-top: 1.5rem;

            h6 {
                color: #999999;
                margin-bottom: 0.25rem;
            }

            h5 {
                font-size: 1.15rem;
            }
        }
    }
}

.sections_chat {
    display: none !important;

    &.active_sec {
        display: flex !important;
        flex-direction: column;
    }
}

// .overflow_wpr {
//   padding: 0.5rem 0rem;
//   overflow: auto;
//   max-height: 480px;
//   @include scrollbar_vertical;
// }
