.wrapper_register {
  background: #eeeeee;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  .line{
    line-height: 1;
  }
  .bg_register {
    width: 100%;
    max-width: 1300px;
    min-width: 990px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .box_header{
    position: absolute;
    width: 100%;
    max-width: 1250px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: right;
    z-index: 9;
    padding: 0 1rem;
    a{
      display: inline-block;
      background: var(--primary_color);
      color: #efefef;
      padding: .6rem 1.5rem;
      border-radius: 2rem;
      font-weight: 600;
      font-size: .90rem;
      cursor: pointer;
      box-shadow: 3px 2px 12px rgba(0, 0, 0, 0.4);
      &:hover{
       box-shadow: none;
      }
    }
  }
  .container_register {
    position: relative;
    z-index: 1;
    padding-top: 170px;
    padding-bottom: 3rem;
    .drawer_card_register {
      background: #ffffff;
      box-shadow: 0 0 22px rgba(0, 0, 0, 0.1);
      max-width: 600px;
      margin: 0 auto;
      padding: 0.85rem 2rem 1.5rem;
      border-radius: 0.75rem;
      .box_brand_register {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 100%;
          margin-right: 1rem;
        }
        img {
          max-height: 80px;
        }
      }
      .box_points_step {
        width: 100%;
        max-width: 400px;
        margin: 1.25rem auto 1rem;
        display: flex;
        justify-content: space-between;
        position: relative;
        &::before {
          content: "";
          height: 0;
          border-bottom: 3px dashed #cccccc;
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
        &.bar_full::before {
          border-bottom: 3px solid var(--primary_color);
        }
        .point_step {
          position: relative;
          z-index: 1;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.1rem;
          color: #bbbbbb;
          border: 3px solid #bbbbbb;
          background: #ffffff;
          &.active {
            border-color: var(--primary_color);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
            color: #ffffff;
            background: var(--primary_color);
          }
        }
      }
      .box_form_register {
        .box_input_icons {
          position: relative;
          .ico_show_pass {
            position: absolute;
            top: 2.2rem;
            right: 0.25rem;
            font-size: 0.9rem;
            border-radius: 50%;
            padding: 0.25rem 0.33rem;
            cursor: pointer;
            color: #666666;
            &:hover {
              background: #dddddd;
            }
          }
        }
        .box_form_membership {
          .ttl_membership {
            max-width: 400px;
            margin: 0 auto;
            margin-bottom: 1rem;
          }
          .box-ico-pay{
            font-size: 4rem;
            line-height: 1;
            &.success{
              color: #23b107;
            }
            &.cancel{
              color: #c90000;
            }
          }
        }
        .box_form_btns {
          position: relative;
          min-height: 45px;
          .secundary{
            position: absolute;
            left: 0;
            top: 0;
            min-width: 190px;
          }
          .primary{
            position: absolute;
            right: 0;
            min-width: 190px;
            top: 0;
          }
        }
      }
    }
    .nav_legals{
      max-width: 600px;
      margin: 0 auto;
      margin-top: .75rem;
      text-align: right;
      font-size: .85rem;
      font-weight: 600;
      padding-right: .5rem;
      a{
        color: #aaaaaa;
        cursor: pointer;
        &:hover{
          color: var(--primary_color);
        }
      }
    }
  }
}
