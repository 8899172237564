@import '../../assets/theme/vars';

.wrapper_details_company,
.wrapper_company_admin {
    @include bggrey;
    min-height: calc(100vh - 4rem);
}

.content_new_question {
    background: #f8f7f7;
    padding: 1rem;
    margin-bottom: 1.5rem;

    .form-group .form-control {
        background: #ffffff;
    }
}
