@import "../../assets/theme/vars";

.wrapper_help {
  .content_ttls {
    background: #7890fa;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 2rem;
    height: 160px;
    .box_img_surveys {
      text-align: center;
      img {
        width: 75%;
      }
    }
    .minw {
      max-width: 350px;
    }
  }
  .content_tabs_help {
    margin-top: 4rem;
    .nav.nav-pills {
      .nav-item {
        .nav-link {
          background: $white_text;
          outline: none;
          box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          margin-bottom: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          line-height: 1;
          padding: 1.25rem;
          span {
            font-size: 2.1rem;
          }
          h5 {
            margin-bottom: 0;
            font-family: "is_medium";
            padding-left: 1rem;
            font-size: 1.15rem;
          }
          &.active {
            background: var(--primary_color);
          }
        }
      }
    }
  }
  .tab-content {
    padding: 0 0.5rem;
    .content_info_help {
      .ttl_info_help {
        border-bottom: 1px solid #cccccc;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1rem;
        span {
          margin-right: 1rem;
          font-size: 2.5rem;
        }
        h5 {
          font-family: "is_medium";
          font-size: 1.75rem;
          line-height: 1;
        }
      }
    }
  }
}
