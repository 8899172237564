@import "../../assets/theme/vars";

.item_blackboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.15rem;
  border-radius: 6px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background: $bg_grey;
  }
  .box_avatar_user {
    width: 75px;
    min-width: 75px;
    height: 75px;
 
  }
  .info_post {
    width: 100%;
    padding-left: 1rem;
  }
}
