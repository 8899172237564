@import "../../assets/theme/vars";

.wrapper_audience {
  .content_ttls {
    background: #a6d7ff;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 2rem;
    height: 170px;
    margin-bottom: 2rem !important;
    .box_img_surveys {
      text-align: center;
      min-width: 180px;
      img {
        width: 100%;
      }
    }
    .minw {
      max-width: 400px;
    }
  }
  .content_card_audience {
    padding: 1rem .25rem .25rem;
    .box_list_audience {
      width: 100%;
      .item_audience {
        .box_img_stories {
          max-height: 160px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: .5rem;
          border-radius: $border_radius;
          img{
            border-radius: $border_radius;
            width: 100%;
          }
        }
        .box_info_audience {
          width: 100%;
          padding-right: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .box_icon {
            min-width: 65px;
            font-size: 2.5rem;
            text-align: center;
          }
        }
        .box_btns{
          margin-top: .5rem;
        }
      }
      &.inAudience{
        .nav-tabs{
          margin-bottom: 2rem;
          border-bottom: 1px solid #dee2e6;
        }
      }
    }
  }
  .myp{
    font-size: .90rem;
    min-height: 40px;
    margin-top: .25rem;
    margin-bottom: 0;
  }
}
