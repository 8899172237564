.content_user_activity {
  .content_history_activity {
    .box_ttls_activity {
      background: #eeeeee;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      & > div {
        width: 100%;
        &:first-child {
          width: 125px;
        }
      }
    }
    .box_list_activity {
      .item_list_activity {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & > div {
          padding: .5rem 0.25rem;
          width: 100%;
          &:first-child {
            width: 90px;
            position: relative;
            z-index: 9;
            min-height: 70px;
            &::after {
              content: "";
              width: 2px;
              top: 0;
              left: 28px;
              height: 100%;
              position: absolute;
              background: #cccccc;
            }
          }
          .ico_Activity {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 3px solid #cccccc;
            background: #ffffff;
            font-size: 1.25rem;
            color: #bbbbbb;
            position: relative;
            z-index: 9;
          }
        }
        .item_description {
          padding: 0;
          height: 100%;
          .name_activity{
            font-family: 'is_medium';
          }
          .data_activity{
            color: #aaaaaa;
            font-size: .85rem;
          }
        }
      }
    }
  }
}
