@import "../../../assets/theme/vars";

.wrapper_shoppingHistory {
	@include bggrey;
	min-height: calc(100vh - 4rem);

	.contain_shop_history {
		.boxes_hop_history {
			border-radius: 8px;
			padding: 1rem;
			padding-top: 0;
			.paginated_page {
				text-align: end;
				button {
					min-width: 36px;
					border: 10px;
					box-sizing: border-box;
					display: inline-block;
					-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
					cursor: pointer;
					text-decoration: none;
					margin: 0px;
					padding: 0px;
					outline: none;
					font-size: inherit;
					font-weight: inherit;
					position: relative;
					height: 36px;
					line-height: 36px;
					min-width: 36px;
					color: rgba(0, 0, 0, 0.87);
					transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
					border-radius: 2px;
					user-select: none;
					overflow: hidden;
					background-color: rgba(0, 0, 0, 0);
					text-align: center;
					border-radius: 45px;
					&.isActive {
						color: var(--primary_color) !important;
					}
					&:hover {
						background-color: rgba(153, 153, 153, 0.2);
					}
				}
			}
		}
	}
}
