@import "../../assets/theme/vars";

.wrapper_slide_home {
  position: relative;
  border-radius: $border_radius;
  overflow: hidden;
  .slick-slide{
    line-height: 0;
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    background: rgba(255, 255, 255, 0.25);
    position: absolute;
    top: calc(50% - 5px);
    display: block;
    width: 20px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 9;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    opacity: 0.6;
  }

  .slick-prev {
    left: 10px;
  }
  .slick-next {
    right:10px;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    opacity: 1;
    outline: none;
  }
  .slick-prev:before,
  .slick-next:before {
    content: "";
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    z-index: 9;
  }

  .slick-prev:before {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 8px solid #444444;
    border-bottom: 5px solid transparent;
  }

  .slick-next:before {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 8px solid #444444;
    border-bottom: 5px solid transparent;
  }
}
