.wrapper_tool_discount {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 310px;
  .item_link_op {
    text-align: center;
    background: #ffffff;
    margin: 0 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem 0.8rem;
    border-radius: 0.35rem;
    font-size: 0.9rem;
    &:hover {
      box-shadow: none;
    }
    .fas {
      font-size: 1rem;
    }
  }
}
