 .box_paymethod{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 .5rem;
    .item_paymethod{
      background: #ffffff;
      margin: .35rem;
      display: flex;
      justify-content: center;
      padding: .3rem .5rem;
      border-radius: .35rem;
      border: 1px solid #e4edf8;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
      &:hover{
        box-shadow: none;
      }
      img{
        height: 35px;
      }
    }
  }