@import '../../assets/theme/vars';

.box_item_purchase_details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: .90rem;
    margin: 1.25rem 0;

    .btn_delete_item {
        padding-right: .75rem;
        cursor: pointer;
        color: #aaaaaa;

        &:hover {
            color: red;
        }
    }

    .item_details {
        * {
            margin: 0
        }

        .price_unit {
            font-weight: 400;
            color: #aaaaaa;
            padding-top: .15rem;
            font-size: .80rem;
        }
    }

    .box_catidad {
        min-width: 60px;
        margin-left: 1rem;
    }

    .box_item_total {
        padding-left: .75rem;
    }
}
